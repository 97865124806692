import formHandling from "./formHandling"

(() => {
  console.log("Load Footer Module");
  $(document).ready(function () {
    // Google Analytics Track logic
    $(document).on("click", "[data-gatrack]", function (e) {
      var $link = $(this);
      var commaSeparatedEventData = $link.data("gatrack");
      var eventParams = commaSeparatedEventData.split(",");

      if (!eventParams) {
        return;
      }

      eventCategory = eventParams[0];
      eventAction = eventParams[1];
      eventLabel = eventParams[2];

      dataLayer.push({
        event: eventAction,
        eventCategory: eventCategory,
        eventLabel: eventLabel,
      });
    });
    Webflow.push(function () {
      // Handling can be placed here
      formHandling();
    });

  });
})();
